<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="view_offer" aria-labelledby="sidebar-no-header-title" width="90%" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{lang.view_offer}}</span>
                </div>
                <div @click="hide" id="hideViewCus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                <v-col cols="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="tobbuttom backBlack" style="border:1px solid #bbb !important;background:#ccc !important;padding:1px;width:100%;text-align:center;">
                        <b-button class="mytab1 btn btn-sm btn-success m-2" style="font-weight:600;color:#fff !important;background:#000 !important;border:2px solid #fff !important;font-size:1.1rem !important;width:150px;white-space: nowrap;" @click="resetExpen()" v-b-toggle.add_offer_expenses>التكاليف التشغيلية</b-button> 
                        <b-button class="mytab1 btn btn-sm btn-success m-2" style="font-weight:600;color:#fff !important;background:#000 !important;border:2px solid #fff !important;font-size:1.1rem !important;width:150px;white-space: nowrap;" @click="resetProds()" v-b-toggle.add_offer_porducts >الاصناف المباعة</b-button> 
                        <b-button class="mytab1 btn btn-sm btn-success m-2" style="font-weight:600;color:#fff !important;background:#000 !important;border:2px solid #fff !important;font-size:1.1rem !important;width:145px;" @click="resetCamps()" v-b-toggle.add_offer_addCamps>الحملة الاعلانية</b-button> 
                        <b-button style="font-weight:600;color:#fff !important;border:2px solid #fff !important;font-size:1.1rem !important;background:#000 !important;;width:150px;" class="mytab2 btn btn-sm btn-primary m-2" @click="resetUpdate()" v-b-toggle.updateOffer>التعديل</b-button> 
                        <b-button style="font-weight:600;color:#fff !important;border:2px solid #fff !important;font-size:1.1rem !important;background:#000 !important;;width:150px;" class="mytab2 btn btn-sm btn-primary m-2" @click="sendToApprove()">طلب تعميد</b-button> 
                        <!-- <b-button style="font-weight:600;color:#fff !important;border:2px solid #fff !important;font-size:1.1rem !important;background:#000 !important;;width:150px;" class="mytab2 btn btn-sm btn-primary m-2" >طلب شراء</b-button>  -->
                    </div>
                </v-col>
            </v-row>
            <v-row :style="`margin:auto;width:100%;direction:rtl;text-align:right`">
                <v-col cols="12" md="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th v-for="(item,index) in headers" :key="index"
                                    :class="item.class + ` text-center pb-1`"
                                >{{item.text}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="width:100px;text-align:center;">{{ campaign.campaign_start.substring(0,10)}}</td>
                                <td style="width:100px;text-align:center;">{{ campaign.campaign_end.substring(0,10)}}</td>
                                <td>{{ campaign.campaign_title }}</td>
                                <td>{{ campaign.vehicle_type }}</td>
                                <td>{{ campaign.car_year }}</td>
                                <td>{{ campaign._campaign_area }}</td>
                                <td>{{ campaign.campaign_target }}</td>
                                <td>{{ $RoundNums(campaign.campaign_price) }}</td>
                                <td>{{ campaign._created_by }}</td>
                                <td style="width:100px;text-align:center;">{{ campaign.created_date.substring(0,10)}}</td>
                                <td style="text-align:center;">0</td>
                                <td style="text-align:center;">0</td>
                                <td style="text-align:center;">0</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="backBlack text-center p-1 mb-1">المصاريف التشغيلية</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">الوصف</th>
                                <th class="backBlack text-center p-1">الكمية</th>
                                <th class="backBlack text-center p-1">السعر</th>
                                <th class="backBlack text-center p-1">الاجمالي</th>
                                <th class="backBlack text-center p-1">الضريبة</th>
                                <th class="backBlack text-center p-1">الاجمالي بعد الضريبة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campaign.costs" :key="index">
                                <td class="text-center">{{ item.description }}</td>
                                <td class="text-center">{{ $RoundNum(item.qty) }}</td>
                                <td class="text-center">{{ $RoundNum(item.price) }}</td>
                                <td class="text-center">{{ $RoundNum(item.total) }}</td>
                                <td class="text-center">{{ $RoundNum(item.vat) }}</td>
                                <td class="text-center">{{ $RoundNum(item.ftotal) }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                    <div class="backBlack text-center p-1 mb-1">الحملات الاعلانية</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">اسم الحملة</th>
                                <th class="backBlack text-center p-1">مكان الاعلان</th>
                                <th class="backBlack text-center p-1" style="width:80px;">التكلفة المتوقعة</th>
                                <th class="blackBack text-center p-1" style="width:80px;">الاجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campaign.camps" :key="index">
                                <td class="text-center">{{ item.title }}</td>
                                <td class="text-center">{{ item.adds_from }}</td>
                                <td class="text-center">{{ item.expected_cost }}</td>
                                <td
                                    style="
                                    cursor: pointer;
                                    width: 80px;
                                    border-top: 2px double  #bbb !important;
                                    padding:3px;
                                    text-align: center;
                                    "
                                    class="blackBack"
                                >
                                    <div @click="getDets(index)">
                                        {{lang.view}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                    <div class="backBlack text-center p-1 mb-1">التعميدات</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">تاريخ الطلب</th>
                                <th class="backBlack text-center p-1">المرسل</th>
                                <th class="backBlack text-center p-1">تاريخ التعميد</th>
                                <th class="backBlack text-center p-1">التعميد بواسطة</th>
                                <th class="backBlack text-center p-1">الملاحظات</th>
                                <th class="backBlack text-center p-1">الحالة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campaign.approved" :key="index">
                                <td class="text-center">{{ item.sent_date }}</td>
                                <td class="text-center">{{ item._sent_by }}</td>
                                <td class="text-center">{{ item.approved_date }}</td>
                                <td class="text-center">{{ item._approved_by }}</td>
                                <td class="text-center">{{ item.approve_notes }}</td>
                                <td class="text-center">{{ item._status[`name${lang.langname}`] }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    <div class="backBlack text-center p-1 mb-1">الاصناف المباعة</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">رمز الصنف</th>
                                <th class="backBlack text-center p-1">الصنف</th>
                                <!-- <th class="backBlack text-center p-1">نوع الصنف</th> -->
                                <th class="backBlack text-center p-1">الكمية</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campaign.products" :key="index">
                                <td class="text-center">{{ item.itemcode }}</td>
                                <td class="text-center">{{ item.item_name }}</td>
                                <td class="text-center">{{ $RoundNum(item.qty) }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-col>
                <v-col cols="12" md="6" :style="`direction:`+lang.dir+`;text-align:`+lang.algin+`;`">
                    
                </v-col>
            </v-row>
        </div>
        </template>
        
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
        <offerExpenses ref="offerExpenses" />
        <offerCampaign ref="offerProds" />
        <offerCamps ref="offerCamps" />

        <div class="cusModal" v-if="showtable">
            <div class="cusModalCont">
                <div class="cusModalTitle backBlack">{{ table_titel }}
                    <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
                </div>
                <div class="cusModalBody">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">الوصف</th>
                                <th class="backBlack text-center p-1" style="width:200px;">المواصفات</th>
                                <th class="backBlack text-center p-1" style="width:80px;">التكلفة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campDet" :key="index">
                                <td class="text-center p-1">{{ item.description }}</td>
                                <td class="text-center p-1">{{ item.details }}</td>
                                <td class="text-center p-1">{{ item.cost }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </div>
            </div>
        </div>
        <editOffer ref="editOffer" />
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
import offerExpenses from '@/components/OfferExpenses.vue'
import offerCampaign from '@/components/OfferCampagin.vue'
import offerCamps from '@/components/OfferAddCampaign.vue'
import editOffer from '@/components/updateOffer.vue'
export default{
    components: {offerExpenses,offerCampaign,offerCamps,editOffer},
    data(){
        return{
            showtable: false,
            table_titel: 'المواد الاعلانية',
            campaign: {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
                status: 1,
                _campaign_area: '',
                _created_by: '',
                created_date: '',
                cost: 0,
                costs: [],
                products: [],
                camps: [],
                approved: [],

            }, 
            serviceList: [],
            branchies: [],
            campDet: [],
        }
    },

    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        brachiesList: function(){
            let t = [{text: this.lang.all_branches, value: 0}]
            for(let i = 0;i<this.branchies.length;i++){
                t.push({text: this.branchies[i][`name_${this.lang.langname}`], value: this.branchies[i].id})
            }
            return t;
        },
        checkItems: function(){
            let t = [];
            console.log(this.serviceList);
            return t;
        },
        ariasList: function(){
            return [
                {text: this.lang.all_regions, value: 1},
                {text: this.lang.exact_city, value: 2},
            ]
        },
        headers: function(){
            let t=[]
            
            
            t.push({
                text: this.lang.start_date,
                align: 'center',
                filterable: true,
                value: 'mobile1',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.end_date,
                align: 'center',
                filterable: true,
                value: 'mobile2',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_title,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.vehicle_type,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.car_year,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_area,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_target,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_price,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.created_by,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.created_date,
                align: 'center',
                filterable: true,
                value: 'exp_date',
                sortable: false,
                class: 'pa-0 backBlack'
            })
            t.push({
                text: this.lang.campaign_cost,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_clients,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
            t.push({
                text: this.lang.campaign_profit,
                align: 'center',
                filterable: true,
                value: 'mobile3',
                sortable: false,
                class: 'pa-0 backBlack',
            })
        return t;
      }
    }, 
    created(){
        // this.getBranches();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        sendToApprove(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','sendToApprove'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",this.campaign.id) 
            post.append("data[type]",'program')
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.getOffers(this.campaign.id)
                }
            })
        },
        closeTable(){
            this.campDet = [];
            this.showtable = false;
        },
        getDets(index) {
            this.showtable = true;
            this.campDet = JSON.parse(this.campaign.camps[index].material)
        },
        resetUpdate(){
            this.$refs.editOffer.getOffers(this.campaign.id);
            this.$refs.editOffer.campaign.id = this.campaign.id
        },
        resetExpen(){
            this.$refs.offerExpenses.resetAllValues();
            this.$refs.offerExpenses.campaign.id = this.campaign.id
        },
        resetProds(){
            this.$refs.offerProds.resetAllValues();
            this.$refs.offerProds.campaign.id = this.campaign.id
        },
        resetCamps(){
            this.$refs.offerCamps.resetAllValues();
            this.$refs.offerCamps.campaign.id = this.campaign.id
        },
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.campaign = response.data.results.data[0]
                }
            })
        },
        resetAllValues(){
            this.campaign = {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
            }
        },
        
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>
