<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_offer_addCamps" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:130px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>الحملات الاعلانية</span>
                </div>
                <div @click="hide" id="hideAddCamps" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="6">
                    <label>اسم الحملة الاعلانية</label>
                    <b-form-input class="inborder" v-model="campaginNew.title" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>الجهة المعلنة</label>
                    <b-form-input class="inborder" v-model="campaginNew.adds_from" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>التكلفة المتوقعة</label>
                    <b-form-input class="inborder" v-model="campaginNew.expected_cost" />
                </v-col>
                <v-col cols="12" md="12">
                    <div class="backBlack text-center p-1 mb-1">المواد الاعلانية</div>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th class="backBlack text-center p-1">الوصف</th>
                                <th class="backBlack text-center p-1" style="width:200px;">المواصفات</th>
                                <th class="backBlack text-center p-1" style="width:80px;">التكلفة</th>
                                <th class="blackBack text-center p-1" style="width:80px;">الاجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in  campaginNew.items" :key="index">
                                <td class="text-center p-1">{{ item.description }}</td>
                                <td class="text-center p-1">{{ item.details }}</td>
                                <td class="text-center p-1">{{ item.cost }}</td>
                                <td class="text-center p-1">
                                    <b-button type="button" variant="danger" style="width:65px;border:none !important;background:red" @click="removeItem(index)">{{$parent.lang.delete}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th><b-form-input class="inborder" v-model="newrow.description" /></th>
                                <th><b-form-input class="inborder" v-model="newrow.details" /></th>
                                <th><b-form-input class="inborder" v-model="newrow.cost" /></th>
                                <th>
                                    <b-button type="button" variant="danger" style="width:65px;border:none !important;background:blue" @click="addItem()">{{$parent.lang.add}}</b-button>
                                </th>
                            </tr>
                        </tfoot>
                    </b-table-simple>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">
                    {{lang.add}}
                </b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data(){
        return{
            campaign: {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
                status: 1,
            },
            campaginNew: {
                title: '',
                adds_from: '',
                expected_cost: '',
                items: [

                ],
            },
            newrow: {
                description: '',
                details: '',
                cost: ''
            }
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    }, 
    created(){
        // this.getBranches();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        removeItem(index) {
            this.campaginNew.items.splice(index,1)
        },
        addItem() {
            this.campaginNew.items.push(this.newrow)
            this.newrow = {
                description: '',
                details: '',
                cost: ''
            }
        },
        addCustomer(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "addOfferCamps");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[offer]", JSON.stringify(this.campaginNew));
            post.append("data[id]", this.campaign.id);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.$parent.getOffers(this.campaign.id);
                document.getElementById('hideAddCamps').click();
            });
        },
        addIt(id){
            // console.log(this.serviceList);
            if(this.campaign.services.includes(id)){
                const x = this.services.indexOf(id)
                this.campaign.services.splice(x,1)
            }else{
                this.campaign.services.push(id);
            }
        },
        resetAllValues(){
            this.campaign = {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
            }
            this.campaginNew = {
                title: '',
                adds_from: '',
                expected_cost: '',
                items: [

                ],
            }
            this.newrow = {
                description: '',
                details: '',
                cost: 0
            }
        },
        calcNew(){
            const t = this.$calcVat(parseFloat(this.newrow.qty) * parseFloat(this.newrow.price),2);
            this.newrow.total = t.tot
            this.newrow.vat = t.vat
            this.newrow.ftotal = t.ftot
        },
        getItem(id){
            this.getOffers(id)
        },
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.campaign = response.data.results.data[0]
                }
            })
        },
        getBranches(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getBranchies'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.branchies = response.data.results.data
                }
            })
        },
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        getItemService() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getItemService");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.serviceList = response.data.results.data
            });
        },
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
</style>