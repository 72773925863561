<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_offer_porducts" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:130px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>الاصاف المباعة</span>
                </div>
                <div @click="hide" id="hideAddProd" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th v-for="(item,index) in headers" :key="index"
                                    :class="item.class + ``" :style="item.style"
                                >{{item.text}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td class="text-center p-1">{{ item.itemcode }}</td>
                                <td class="text-center p-1">{{ item.item_name }}</td>
                                <td class="text-center p-1">{{ item.qty }}</td>
                                <td class="text-center p-1">
                                    <b-button type="button" variant="danger" style="width:65px;border:none !important;background:red" @click="removeItem(index)">{{$parent.lang.delete}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    <b-form-input class="inborder" v-model="newrow.itemcode" @change="getItem(-1)" />
                                </th>
                                <th>
                                    <b-form-input class="inborder" v-model="newrow.item_name" list="item-products" @change="changeMe(-1)" />
                                    <datalist id="item-products">
                                        <option v-for="(item,index) in itemsworkdes" :key="index">{{item.item_code}} -||- {{ item[`item_name${$parent.lang.langname}`] }}</option>
                                    </datalist>
                                </th>
                                <th>
                                    <b-form-input class="inborder" v-model="newrow.qty" />
                                </th>
                                <th>
                                    <b-button type="button" variant="danger" style="width:65px;border:none !important;background:blue" @click="addItem()">{{$parent.lang.add}}</b-button>
                                </th>
                            </tr>
                        </tfoot>
                    </b-table-simple>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">
                    {{lang.add}}
                </b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data(){
        return{
            campaign: {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
                status: 1,
            },
            tbrows: [],
            newrow: {
                itemcode: '',
                item_name: '',
                qty: 1,
            },
            serviceList: [],
            branchies: [],
            itemsworkdes: [],
            allProducts: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                { text: "رمز الصنف", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "اسم الصنف", value: "city", class:' text-center pb-1 backBlack', style: '' },
                { text: "الكمية", value: "city", class:' text-center pb-1 backBlack', style: 'width:80px;' },
                { text: "الاجراء", value: "city", class:' text-center pb-1 blackBack', style: 'width:80px;' },
            ]
        },
    }, 
    created(){
        this.getInvSettings();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        changeMe(index){
            if(index == -1){
                const itemn = this.newrow.item_name.split(" -||- ");
                if(itemn.length > 1){
                    this.newrow.itemcode = itemn[0];
                    this.getItem(-1);
                }
            }else{
                const itemn = this.tbrows[index].item_name.split(" -||- ");
                if(itemn.length > 1){
                    this.tbrows[index].itemcode = itemn[0];
                    this.getItem(index);
                }
                this.changeUpdate(index)
            }
            
        },
        getInvSettings() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        // if(res.results.data.users.length != 0){
                        //     for(let i= 0; i < res.results.data.users.length; i++){
                        //         // this.invoice.users.push({
                        //         //     text: res.results.data.users[i].full_name,
                        //         //     value: res.results.data.users[i].id
                        //         // })
                        //     }
                        // }
                        if(res.results.data.products.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            
                            
                            // for(let i= 0; i < res.results.data.products.length; i++){
                            //    this.itemsworkdes.push(res.results.products[i].name);
                            // }
                        }
                        this.itemsworkdes = res.results.data.allproduct;
                        console.log(this.itemsworkdes)
                    }
                }
            )
        },
        removeItem(index) {
            this.tbrows.splice(index,1)
        },
        addItem() {
            this.tbrows.push(this.newrow)
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
            }
        },
        getItem(index){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            if(index == -1){
                post.append('data[itemcode]',this.newrow.itemcode)
            }else{
                post.append('data[itemcode]',this.tbrows[index].itemcode)
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                if(resposne.data.results.data.length != 0){
                    if(index == -1){
                        this.newrow = {
                            id: 0,
                            itemcode: resposne.data.results.data[0].item_code,
                            description: resposne.data.results.data[0].description,
                            item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                            qty: 1,
                        }
                        this.calcKit()
                    }else{
                        if(resposne.data.results.data.length > 0){
                            // alert(resposne.data.results.data[0].item_code);
                            this.tbrows[index] = {
                                id: this.tbrows[index].id,
                                itemcode: resposne.data.results.data[0].item_code,
                                description: resposne.data.results.data[0].description,
                                item_name: resposne.data.results.data[0][`item_name${this.lang.langname}`],
                                qty: 1,
                            }
                            this.changePtype();
                        }
                    }
                    
                    
                }
            });
        },
        addCustomer(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "addOfferProd");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[offer]", JSON.stringify(this.tbrows));
            post.append("data[id]", this.campaign.id);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.$parent.getOffers(this.campaign.id);
                document.getElementById('hideAddProd').click();
            });
        },
        addIt(id){
            // console.log(this.serviceList);
            if(this.campaign.services.includes(id)){
                const x = this.services.indexOf(id)
                this.campaign.services.splice(x,1)
            }else{
                this.campaign.services.push(id);
            }
        },
        resetAllValues(){
            this.campaign = {
                id: 0,
                city: '',
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                campaign_end: '',
                campaign_area: 1, 
                vehicle_type: '',
                car_year: '',
                campaign_target: '',
                campaign_branches: [0],
            }
            this.tbrows = []
            this.newrow = {
                description: '',
                qty: 1,
                price: '',
                total: '',
                vat: '',
                ftotal: ''
            }
        },
        calcNew(){
            const t = this.$calcVat(parseFloat(this.newrow.qty) * parseFloat(this.newrow.price),2);
            this.newrow.total = t.tot
            this.newrow.vat = t.vat
            this.newrow.ftotal = t.ftot
        },
        
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.campaign = response.data.results.data[0]
                }
            })
        },
        getBranches(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getBranchies'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.branchies = response.data.results.data
                }
            })
        },
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        getItemService() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getItemService");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.serviceList = response.data.results.data
            });
        },
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
</style>